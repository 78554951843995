import { useEffect, useState } from "react";
import Button from "../../CommonComponent/Button";
import style from "./ExtractData.module.css";
import APIS from "../../api";
import Loader from "../loader/loader";
import EmailComponent from "../EmailComponent/EmailComponent";

const ExtractData = ({
  setScreens,
  data,
  setData,
  setSendEmailData,
  setFetchDocsBody,
  sendEmailData
}) => {
  const [noDataFound, setNoDataFound] = useState(false);
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState("");
  const [from, setFrom] = useState("");

  useEffect(() => {
    if (data?.From) {
      setFrom(data?.From?.split(" ")[0] + " " + data?.From?.split(" ")[1]);
    }
  }, []);

  const handleOnclick = async () => {
    setLoading(true);
    const res = await APIS.extractData({ content: data?.Body });
    setLoading(false);
    if (res === "No data found" || res === "Something went wrong!") {
      setMsg(res);
      setNoDataFound(true);
    } else {
      setSendEmailData((prev) => ({
        ...prev,
        "Document Name": res?.data["Document Name"],
      }));
      setFetchDocsBody(prev => ({...prev,...res?.data, From: sendEmailData?.From}));
      setData(res?.data);
      setScreens({
        screen1: false,
        screen2: false,
        screen3: true,
        screen4: false,
      });
    }
  };

  return (
    <div className={style.container}>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className={style.box}>
            {noDataFound ? (
              <div className="error">{msg}</div>
            ) : (
              <EmailComponent from={data?.From} subject={data?.Subject} body={data?.Body} />
            )}
          </div>
          {!noDataFound && (
            <div>
              <Button text="Extract" onClick={handleOnclick} />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ExtractData;
