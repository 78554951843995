import { useState } from "react";
import FetchEmail from "./Components/FetchEmail/FetchEmail";
import ExtractData from "./Components/ExtractData/ExtractData";
import FetchDocs from "./Components/FetchDocs/FetchDocs";
import SendEmail from "./Components/SendEmail/SendEmail";

function App() {
  const [screens, setScreens] = useState({
    screen1: true,
    screen2: false,
    screen3: false,
    screen4: false,
  });

  const [data, setData] = useState("");
  const [fetchDocsaBody, setFetchDocsBody] = useState({})
  const [sendEmailData, setSendEmailData] = useState({});

  return (
    <div className="App">
      {screens?.screen1 && (
        <FetchEmail
          setScreens={setScreens}
          setSendEmailData={setSendEmailData}
          data={data}
          setData={setData}
          setFetchDocsBody={setFetchDocsBody}
        />
      )}
      {screens?.screen2 && (
        <ExtractData
          setScreens={setScreens}
          sendEmailData={sendEmailData}
          setSendEmailData={setSendEmailData}
          data={data}
          setData={setData}
          setFetchDocsBody={setFetchDocsBody}
        />
      )}
      {screens?.screen3 && (
        <FetchDocs
          setScreens={setScreens}
          setSendEmailData={setSendEmailData}
          data={data}
          setData={setData}
          fetchDocsaBody={fetchDocsaBody}
        />
      )}
      {screens?.screen4 && (
        <SendEmail
          sendEmailData={sendEmailData}
          setScreens={setScreens}
          data={data}
          setData={setData}
        />
      )}
    </div>
  );
}

export default App;
