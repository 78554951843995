import style from "./EmailComponent.module.css";
import downloadImg from "../../assets/download.png";
import PdfComponent from "../PdfComponent/PdfComponent";

const EmailComponent = ({ from, subject, body, attachments }) => {
  const linkExists = (link) => {
    return link?.split("<")?.length > 1 && link?.split(">")?.length > 1
      ? true
      : false;
  };

  const getLink = (link) => {
    return link && linkExists(link) ? link?.split("<")[1]?.split(">")[0] : link;
  };

  return (
    <div className={style.email}>
      <div className={style.boxWithbBorder}>
        <span
          className={`${style.from} ${style.subjectHeader} ${style.subject}`}
        >
          From &nbsp; &nbsp;
        </span>
        <span
          title={from?.split(" ")[2]?.split("<")[1]?.split(">")[0]}
          className={`${style.from} ${style.subject}`}
        >
          {from}
        </span>
      </div>
      <div className={style.bodyParentcontainer}>
        <div className={style.boxWithbBorder}>
          <span className={`${style.subject} ${style.subjectHeader}`}>
            Subject &nbsp; &nbsp;
          </span>
          <span className={style.subject}>{subject}</span>
        </div>
        <div className={style.bodyContainer}>
          <span className={style.body}>
            {body?.split("\n")?.map((item) => (
              <>
                <div>
                  {item && linkExists(item) ? (
                    <a href={getLink(item)} target="blank">
                      {getLink(item)}
                    </a>
                  ) : (
                    item
                  )}
                </div>
              </>
            ))}
          </span>
          <span>
            <ol className={style.attachmentsList}>
              {attachments &&
                attachments?.length &&
                attachments?.map((item) => (
                  <li>
                    <PdfComponent fileName={item?.file_name} fileUrl={item?.file_url} />
                    {/* <span>{item?.file_name}</span>&nbsp;&nbsp;&nbsp;
                    <span>
                      <a href={item?.file_url} target="blank">
                        <img
                          src={downloadImg}
                          style={{ width: "1rem" }}
                          alt="download pdf"
                        />
                      </a>
                    </span> */}
                  </li>
                ))}
            </ol>
          </span>
        </div>
      </div>
    </div>
  );
};

export default EmailComponent;
