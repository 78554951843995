import axios from "axios";

const axiosConfig = {
  headers: {
    Authorization: `null`, // Replace with your actual token or remove this line
  },
};

const APIS = {
  fetchEmail: async () => {
    try {
      const res = await axios.get(
        "https://dev.service.edelweiss.innovationm.com/fetch_email"
      );

      if (!res) {
        return "No data found";
      }
      return res;
    } catch (err) {
      console.log("Error while fetching email -- ", err);
      return "Something went wrong!";
    }
  },
  extractData: async (data) => {
    try {
      const res = await axios.post(
        "https://dev.service.edelweiss.innovationm.com/extract",
        data,
        axiosConfig
      );
      if (!res) {
        return "No data found";
      }
      return res;
    } catch (err) {
      console.log("Error while extracting data -- ", err);
      return "Something went wrong!";
    }
  },
  fetchDocs: async (data) => {
    try {
      const res = await axios.post(
        "https://dev.service.edelweiss.innovationm.com/fetch_docs",
        data,
        axiosConfig
      );
      if (!res) {
        return "No data found";
      }
      return res;
    } catch (err) {
      console.log("Error while extracting data -- ", err);
      return "Something went wrong!";
    }
  },
  sendEmail: async (data) => {
    try {
      const res = await axios.post(
        "https://dev.service.edelweiss.innovationm.com/email_send",
        data,
        axiosConfig
      );
      if (!res) {
        return "No data found";
      }
      return res;
    } catch (err) {
      console.log("Error while extracting data -- ", err);
      return "Something went wrong!";
    }
  },
};

export default APIS;
