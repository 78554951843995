import style from "./PDfComponent.module.css";

const PdfComponent = ({ fileName, fileUrl }) => {
  return (
    <div className={style.pdfThumbnail}>
      <img src="https://via.placeholder.com/50x50?text=PDF" alt="PDF" />
      <div className={style.fileInfo}>
        <div className={style.fileName}>
          <img src="https://via.placeholder.com/24X24?text=PDF" alt="PDF" />
          <a href={fileUrl} target="blank">{fileName}</a>
        </div>
      </div>
    </div>
  );
};

export default PdfComponent;
