import { useState } from "react";
import Button from "../../CommonComponent/Button";
import style from "./SendEmail.module.css";
import checkImg from "../../assets/check.png";
import APIS from "../../api";
import Loader from "../loader/loader";
import downloadImg from "../../assets/download.png";
import EmailComponent from "../EmailComponent/EmailComponent";

const SendEmail = ({ data, setScreens, sendEmailData }) => {
  const [noDataFound, setNoDataFound] = useState(false);
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState("");

  const handleOnclick = async () => {
    setLoading(true);
    const res = await APIS?.sendEmail(sendEmailData);
    setLoading(false);
    if (res === "No data found" || res === "Something went wrong!") {
      setMsg(res);
      setNoDataFound(true);
    } else {
      setScreens({
        screen1: false,
        screen2: false,
        screen3: false,
        screen4: true,
      });
      setSent(true);
    }
  };

  const [sent, setSent] = useState(false);
  return (
    <div className={style.container}>
      {loading ? (
        <Loader />
      ) : noDataFound ? (
        <div className="error">{msg}</div>
      ) : sent ? (
        <div className={style.successMsg}>
          <span className={style.imgContainer}>
            <img src={checkImg} alt="check-image" />
          </span>
          <span className={style.msg}>Email Sent Successfully</span>
        </div>
      ) : (
        <div
          style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}
        >
        <div className={style.emailContainer}><EmailComponent from={sendEmailData?.From} subject={sendEmailData?.Subject} body={sendEmailData?.Body} attachments={sendEmailData?.files} /></div>
          {/* <ul>
            {data?.files?.map((item) => (
              <li>
                <span>{item?.file_name}</span>&nbsp;&nbsp;&nbsp;
                <span>
                  <a href={item?.file_url} target="blank">
                    <img
                      src={downloadImg}
                      style={{ width: "1rem" }}
                      alt="download pdf"
                    />
                  </a>
                </span>
              </li>
            ))}
          </ul> */}
          <div className={style.btnContainer}>
            <Button text="Send Email" onClick={handleOnclick} />
          </div>
        </div>
      )}
    </div>
  );
};

export default SendEmail;
