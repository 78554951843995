import { useState } from "react";
import Button from "../../CommonComponent/Button";
import style from "./FetchDocs.module.css";
import APIS from "../../api";
import Loader from "../loader/loader";

const FetchDocs = ({
  setScreens,
  data,
  setData,
  setSendEmailData,
  fetchDocsaBody,
}) => {
  const [noDataFound, setNoDataFound] = useState(false);
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState("");

  const handleFetchDocs = async () => {
    setLoading(true);
    const res = await APIS?.fetchDocs(fetchDocsaBody);
    setLoading(false);

    if (res === "No data found" || res === "Something went wrong!") {
      setMsg(res);
      setNoDataFound(true);
    } else {
      setSendEmailData({ ...res?.data });
      setData(res?.data);
      setScreens({
        screen1: false,
        screen2: false,
        screen3: false,
        screen4: true,
      });
    }
  };

  return (
    <div className={style.container}>
      {loading ? (
        <Loader />
      ) : (
        <>
          {noDataFound ? (
            <div className="error">{msg}</div>
          ) : (
            <>
              <div className={style.box}>
                <div>
                  <Box attribute={"Day"} value={data?.Day} />
                  <Box attribute={"Month"} value={data["Month"]} />
                  <Box attribute={"Year"} value={data["Year"]} />
                  <Box
                    attribute={"Document Name"}
                    value={data["Document Name"]}
                  />
                  <Box
                    attribute={"Investor Name"}
                    value={data["Investor Name"]}
                  />

                  <Box attribute={"PAN number"} value={data["PAN number"]} />
                </div>
              </div>
              <div>
                <Button text="Fetch Documents" onClick={handleFetchDocs} />
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

const Box = ({ attribute, value }) => {
  return (
    <div className={style.smallContainer}>
      <span className={style.attribute}>{attribute}</span>
      <span>&nbsp;:&nbsp;</span>
      <span className={style.value}>{value}</span>
    </div>
  );
};

export default FetchDocs;
