import style from "./loader.module.css"

const Loader = () => {
    return (<div class={style.loaderellipsis}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>)
}

export default Loader