import { useState } from "react";
import APIS from "../../api";
import apis from "../../api";
import Button from "../../CommonComponent/Button";
import style from "./FetchEmail.module.css";
import Loader from "../loader/loader";

const FetchEmail = ({ setScreens, data, setData, setSendEmailData, setFetchDocsBody }) => {
  const [noDataFound, setNoDataFound] = useState(false);
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState("");

  const handleEmail = async () => {
    setLoading(true);
    const res = await APIS.fetchEmail();
    setLoading(false);
    if (
      res === "No data found" ||
      res === "Something went wrong!"
    ) {
      setMsg(res);
      setNoDataFound(true);
    } else {
      setData(res?.data);
      setSendEmailData(prev => ({...prev, From: res?.data?.From}))
      setFetchDocsBody(prev => ({...prev, Subject: res?.data?.Subject}))
      setScreens({
        screen1: false,
        screen2: true,
        screen3: false,
        screen4: false,
      });
    }
  };
  return (
    <div className={style.container}>
      {loading ? (
        <Loader />
      ) : (
        <>
          {noDataFound ? (
            <div className="error">{msg}</div>
          ) : (
            <Button text="Fetch" onClick={handleEmail} />
          )}
        </>
      )}
    </div>
  );
};

export default FetchEmail;
